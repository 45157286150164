<template>
  <div v-if="isVisible" class="floating-cheer-button" :style="buttonStyle" @click="onClick">
    <v-btn fab color="primary">
      응원
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      position: { x: 0, y: 0 },
      velocity: { x: 20, y: 20 },
      isVisible: true,
    };
  },
  computed: {
    buttonStyle() {
      return {
        position: 'fixed',
        left: `${this.position.x}px`,
        top: `${this.position.y}px`,
        transition: 'all 0.1s ease-out',
      };
    },
  },
  mounted() {
    this.initPosition();
    this.startMoving();
  },
  beforeDestroy() {
    this.stopMoving();
  },
  methods: {
    initPosition() {
      this.position.x = Math.random() * (window.innerWidth - 100);
      this.position.y = Math.random() * (window.innerHeight - 100);
    },
    startMoving() {
      this.moveInterval = setInterval(this.move, 50);
    },
    stopMoving() {
      clearInterval(this.moveInterval);
    },
    move() {
      this.position.x += this.velocity.x;
      this.position.y += this.velocity.y;

      if (this.position.x <= 0 || this.position.x >= window.innerWidth - 100) {
        this.velocity.x *= -1;
      }
      if (this.position.y <= 0 || this.position.y >= window.innerHeight - 100) {
        this.velocity.y *= -1;
      }
    },
    onClick() {
      this.isVisible = false;
      this.$emit('toggle-cheer-video');
    },
  },
};
</script>

<style scoped>
.floating-cheer-button {
  z-index: 1000;
}
</style>