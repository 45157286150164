<!-- DiscussionModal.vue -->
<template>
  <v-dialog
    :value="value"
    max-width="500"
    @input="$emit('input', $event)"
  >
    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h7 text-medium-emphasis ps-2">
          <v-icon
            size="18"
            color="#4285f4"
            class="mr-2"
          >
            mdi-lightbulb
          </v-icon>
          {{ title }}
        </div>
        <button
          class="cancel-btn"
          @click="$emit('cancel')"
        >
          <v-icon size="34">
            mdi-close-circle-outline
          </v-icon>
        </button>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text class="text-center">
        <div class="mt-2 mb-2 font-weight-bold">
          "{{ chatName }}"
        </div>

        <div class="text-medium-emphasis mb-1">
          <template v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </template>
          <template v-else>
            <span :style="{ color: isDiscussionMode ? '#4285f4' : 'black' }">{{ serverResponse }}</span>
          </template>
        </div>
      </v-card-text>

      <v-divider class="mt-1" />

      <v-card-actions class="my-2 d-flex justify-end">
        <template v-if="isDiscussionMode">
          <button
            class="create-btn improved-btn"
            :disabled="loading"
            @click="$emit('confirm', 'improved')"
          >
            개선 문장
          </button>
          <button
            class="create-btn original-btn"
            @click="$emit('confirm', 'original')"
          >
            나의 문장
          </button>
        </template>
        <template v-else>
          <button
            class="create-btn improved-btn"
            @click="$emit('confirm', 'original')"
          >
            시작
          </button>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DiscussionModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    chatName: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    serverResponse: {
      type: [String, Array],
      default: ''
    },
    isDiscussionMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'confirm', 'cancel']
}
</script>

<style scoped>
.create-btn {
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 16px;
  height: 44px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  margin-left: 10px; /* 버튼 간 간격 유지 */
}

.improved-btn {
  background-color: #4285f4;
  color: #ffffff;
}

.improved-btn:hover {
  background-color: #3367d6;
}

.original-btn {
  background-color: #ffffff;
  color: #4285f4;
  border: 2px solid #4285f4;
}

.original-btn:hover {
  background-color: #e8f0fe;
}

.create-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
