<template>
  <div id="video-container">
    <!-- 동영상 박스 -->
    <div class="video-box">
      <video
        ref="Player"
        :src="videoSrc"
        autoplay
        loop
        muted
        @error="handleError($refs.Player)"
      >
        브라우저가 비디오 태그를 지원하지 않습니다.
      </video>
      <!-- 응원 메시지 추가 -->
      <div class="cheer-message">
        {{ cheerMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value)
    }
  },
  data() {
    return {
      // videoSrc: "https://www.cunshao.com/666666/api/web.php",
      // videoSrc: "https://tucdn.wpon.cn/api-girl/index.php?wpon=302"
      // videoSrc: "http://v.nrzj.vip/video.php"  // 못생기고 좀 저질
      // videoSrc: "http://api.yujn.cn/api/zzxjj.php"  // 이쁜 여자 얼굴 위주
      videoSrc: "http://api.yujn.cn/api/zzxjj.php?type=video"
      // videoSrc: "https://tucdn.wpon.cn/api-girl/index.php?wpon=302" // 얼굴가림 
    };
  },
  computed: {
    cheerMessage() {
      return this.position === 'left' ? '찬성 응원영상' : '반대 응원영상';
    }
  },
  methods: {
    handleError(player) {
      // 에러 발생 시 비디오를 다시 로드하고 재생
      player.load();
      player.play();
    },
  },
};
</script>

<style scoped>
#video-container {
  display: flex;
  margin-top: 10px;
}
.video-box {
  width: 170px; /* 레이아웃에 맞게 너비를 조정하세요 */
  height: 300px;
  background-color: #f0f0f000;
  border-radius: 10px;
  padding: 0px;
  position: relative; /* 자식 요소의 절대 위치 지정을 위해 추가 */
}
video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.cheer-message {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  white-space: nowrap;
}
</style>
