<template>
  <div class="signin-container">
    <p v-if="error">
      {{ error }}
    </p>
    <p v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="30"
      />
      <span class="ml-2 font-weight-bold">익명 사용자로 로그인 중입니다...</span>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user', 'error', 'loading'])
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  mounted() {
    this.onSignin();
  },
  methods: {
    ...mapActions(['signUserIn', 'clearError']),
    onSignin() {
      this.signUserIn()
        .catch((error) => {
          console.error("로그인 실패:", error);
          this.clearError();
        });
    }
  }
}
</script>

<style scoped>
.signin-container {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 전체 화면 높이를 차지하도록 설정 */
}
</style>
