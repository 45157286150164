<template>
  <v-container
    class="threecard-container"
    @scroll="onScroll"
  >
    <v-row
      align="center"
    >
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <v-divider color="grey lighten-2" />
        <span class="mx-4 font-weight-bold white-space-nowrap">투표 많은 순 TOP3</span>
        <v-divider color="grey lighten-2" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(chat, index) in sortedChats"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        class="mb-4"
      >
        <v-card
          class="mx-auto chat-card"
          elevation="2"
          :class="{ 'on-hover': hoverChat === chat.key }"
          @mouseover="hoverChat = chat.key"
          @mouseleave="hoverChat = null"
          @click="enterChat(chat)"
        >
          <div class="medal-icon">
            {{ getMedalIcon(index) }}
          </div>
          <v-img
            :src="''"
            height="120"
            class="black--text align-center"
            :style="{ boxShadow: '0 0 10px rgba(150, 150, 150, 0.5)' }"
          >
            <v-card-title class="topic-title">
              "{{ chat.name }}"
            </v-card-title>
          </v-img>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-2">
              <span style="color: #00C853;">찬성</span>
              <span style="color: #E64A19;">반대</span>
            </div>
            <v-progress-linear
              :value="100"
              height="25"
              color="grey lighten-2"
            >
              <div class="progress-bar-wrapper">
                <div
                  class="progress-bar-approval"
                  :style="{ width: approvalPercentage(chat.approvals, chat.disapprovals) + '%' }"
                >
                  <span>{{ chat.approvals }}</span>
                </div>
                <div
                  class="progress-bar-rejection"
                  :style="{ width: rejectionPercentage(chat.approvals, chat.disapprovals) + '%' }"
                >
                  <span>{{ chat.disapprovals }}</span>
                </div>
              </div>
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onValue, update } from 'firebase/database';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      chats: [],
      hoverChat: null,
    };
  },
  computed: {
    sortedChats() {
      return [...this.chats]
        .sort((a, b) => {
          const votesA = a.approvals + a.disapprovals;
          const votesB = b.approvals + b.disapprovals;
          if (votesB !== votesA) {
            return votesB - votesA;
          } else {
            return new Date(b.date) - new Date(a.date);
          }
        })
        .slice(0, 3);
    },
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.loadChats();
  },
  methods: {
    ...mapActions(['setCurrID', 'setIsViewList']),
    onScroll() {
      // 스크롤 이벤트 처리 로직 추가
    },
    loadChats() {
      const database = this.$store.state.firebaseDatabase;
      const chatsRef = ref(database, 'chats');
      onValue(chatsRef, (snapshot) => {
        const chatData = [];
        snapshot.forEach((childSnapshot) => {
          const chat = childSnapshot.val();
          chat.key = childSnapshot.key;
          chat.approvals = this.getMessageCount(chat.pro);
          chat.disapprovals = this.getMessageCount(chat.con);
          chatData.push(chat);
        });
        this.chats = chatData;
      });
    },
    getMessageCount(messages) {
      if (!messages) return 0;
      return Array.isArray(messages) ? messages.length : Object.keys(messages).length;
    },
    approvalPercentage(approvals, disapprovals) {
      const total = approvals + disapprovals;
      return total ? (approvals / total) * 100 : 50;
    },
    rejectionPercentage(approvals, disapprovals) {
      const total = approvals + disapprovals;
      return total ? (disapprovals / total) * 100 : 50;
    },
    getMedalIcon(index) {
      const icons = ['🥇', '🥈', '🥉'];
      return icons[index] || '';
    },
    enterChat(chat) {
      let chatId = chat.key;
      let time = new Date().valueOf();

      if (chat.isAlreadyJoined) {
        this.setCurrID(chatId);
        this.setIsViewList(false);
      } else {
        let updates = {};
        updates['/chat_members/' + chatId + '/users/' + this.user.id] = {timestamp: time};
        updates['users/' + this.user.id + '/chats/' + chatId] = {timestamp: time};

        const database = this.$store.state.firebaseDatabase;
        update(ref(database), updates).then(() => {
          this.setCurrID(chatId);
          this.setIsViewList(false);
        });
      }
    },
  }
}
</script>

<style scoped>
.threecard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.chat-card {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.chat-card.on-hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.12);
}

.progress-bar-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.progress-bar-approval,
.progress-bar-rejection {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  padding: 0 5px;
}

.progress-bar-approval {
  background-color: #00C853;
  justify-content: flex-start;
}

.progress-bar-rejection {
  background-color: #E64A19;
  justify-content: flex-end;
}

.topic-title {
  font-size: 1rem;
  color: #718096;
  text-align: left;
  font-weight: bold;
}

.medal-icon {
  position: absolute;
  top: -20px;
  left: 5px;
  font-size: 24px;
  z-index: 1;
}

@media (max-width: 600px) {
  .threecard-container {
    padding: 0.5rem;
  }

  .topic-title {
    font-size: 0.9rem;
  }

  .progress-bar-approval,
  .progress-bar-rejection {
    font-size: 0.8rem;
  }
}
</style>
