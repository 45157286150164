<template>
  <div class="home-container">
    <v-container
      fluid
      class="pa-0 fill-height"
    >
      <v-row
        justify="center"
        no-gutters
        class="fill-height"
      >
        <v-col
          cols="12"
          xs="1"
          sm="1"
          md="2"
          lg="2"
          xl="2"
          class="d-none justify-center align-center d-md-flex"
        >
          <div v-if="!isViewList && currID">
            <voting-comp
              class="mb-3"
              vote-type="pro"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="10"
          sm="10"
          md="8"
          lg="8"
          xl="8"
          class="fill-height"
        >
          <div class="content-wrapper">
            <div v-if="!isViewList">
              <template v-if="currID">
                <voting-result class="mb-0" />
                <chat class="chat-container" />
              </template>
              <template v-else>
                <introduction-area class="mb-3" />
                <create class="mb-1" />
                <three-card />
              </template>
            </div>
            <div v-else>
              <chat-list class="mb-3" />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="1"
          sm="1"
          md="2"
          lg="2"
          xl="2"
          class="d-none justify-center align-center d-md-flex"
        >
          <div v-if="!isViewList && currID">
            <voting-comp
              class="mb-3"
              vote-type="con"
            />
          </div>
        </v-col>
      </v-row>
      <!-- 새로운 row 추가 -->
      <v-row
        v-if="!isViewList && currID"
        justify="center"
        no-gutters
        class="voting-row d-flex d-md-none"
        style="pointer-events: none;"
      >
        <v-col
          cols="3"
          class="d-flex justify-center align-center"
        >
          <voting-comp
            class="voting-comp"
            style="pointer-events: auto;"
            vote-type="pro"
          />
        </v-col>
        <v-col cols="6" />
        <v-col
          cols="3"
          class="d-flex justify-center align-center"
        >
          <voting-comp
            class="voting-comp"
            style="pointer-events: auto;"
            vote-type="con"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import IntroductionArea from './Chat/IntroductionArea.vue'
import Create from './Chat/Create.vue'
import Chat from './Chat/Chat.vue'
import VotingResult from './Chat/VotingResult.vue'
import ThreeCard from './Chat/parts/ThreeCard.vue'
import ChatList from './Chat/ChatList.vue'
import VotingComp from './Chat/VotingComp.vue'

export default {
  components: {
    IntroductionArea,
    Create,
    Chat,
    VotingResult,
    ThreeCard,
    ChatList,
    VotingComp,
  },
  computed: {
    currID() {
      return this.$store.getters.currID;
    },
    isViewList(){
      return this.$store.getters.isViewList;
    }
  }
}
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding-top: 32px; /* 앱 바 높이만큼 상단 패딩 추가 */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0.4) 100%
  );
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.chat-container {
  flex-grow: 1;
}

@media (max-width: 600px) {
  .home-container {
    padding-top: 56px; /* 모바일에서는 앱 바 높이가 56px */
  }

  .content-wrapper {
    padding: 0.5rem;
    padding-bottom: 0px;
  }
}

.voting-row {
  position: fixed;
  width: 100%;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.voting-comp {
  transform: scale(0.7);
}

</style>
