<template>
  <div class="button-container">
    <div
      v-if="voteType === 'pro'"
      class="text-center"
    >
      <div v-if="myVote === 'pro'">
        <v-icon
          color="green"
          size="24"
        >
          mdi-check-circle-outline
        </v-icon>
      </div>
      <div v-else-if="myVote === 'con'" />
      <div
        v-else
        class="description"
      >
        <span class="positive">찬성</span>에 공감하신다면 클릭하세요👇👇
      </div>
    </div>
    <div
      v-else-if="voteType === 'con'"
      class="text-center"
    >
      <div v-if="myVote === 'pro'" />
      <div v-else-if="myVote === 'con'">
        <v-icon
          color="#E64A19"
          size="24"
        >
          mdi-check-circle-outline
        </v-icon>
      </div>
      <div
        v-else
        class="description"
      >
        <span class="negative">반대</span>에 공감하신다면 클릭하세요👇👇
      </div>
    </div>
    <div :class="['glow-effect', voteType === 'pro' ? 'pro-glow' : 'con-glow']" />
    <v-btn
      icon
      :disabled="isButtonDisabled"
      class="transparent-btn hover-effect"
      @click="exeVote"
    >
      <img
        :src="getImage(voteType)"
        :alt="voteType === 'pro' ? '찬성' : '반대'"
        class="vote-image"
      >
    </v-btn>
  </div>
</template>

<script>
import { ref, update, onValue } from 'firebase/database';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    voteType: {
      type: String,
      required: true,
      validator: value => ['pro', 'con'].includes(value) // 찬성 또는 반대만 허용
    }
  },
  data() {
    return {
      myVote : "",
      voteImages: {
        pro: this.$preloadedImages.pro,
        con: this.$preloadedImages.con,
      }
    };
  },
  computed: {
    ...mapGetters(['currID', 'hasVoted']),
    isButtonDisabled() {
      return this.hasVoted; // 버튼 비활성화 여부
    },
  },
  watch: {
    hasVoted: {
      handler(newValue) {
        this.myVote = "";
        this.checkVoteStatus(this.currID); // 투표 여부 확인
      },
    }
  },
  mounted() {
    if (this.currID !== null) {
      this.setHasVoted(false); // 투표여부 초기화 하고,
      this.checkVoteStatus(this.currID); // 투표 여부 확인
    }
    // console.log('Preloaded images:', this.$preloadedImages);
    // console.log('Mounted voteImages:', this.voteImages);
  },
  methods: {
    ...mapActions(['setHasVoted', 'updateVoteResult']),
    async exeVote() {
      const chatId = this.currID; // 현재 채팅 ID 가져오기
      const userId = this.$store.getters.user.id; // 사용자 ID 가져오기
      const timestamp = new Date().valueOf(); // 현재 시간 가져오기

      // DB에 투표 업데이트
      const database = this.$store.state.firebaseDatabase;
      const updates = {};
      updates[`chats/${chatId}/${this.voteType}/${userId}`] = timestamp;
      await update(ref(database), updates)
        .then(() => {
          this.setHasVoted(true); // 업데이트가 성공적으로 되었으면 투표 완료 상태로 변경
          // 투표 결과 store에 업데이트
          this.updateVoteResult({ chatId, voteType: this.voteType });
        })
        .catch((error) => {
          console.error("투표 업데이트 오류:", error); // 오류 처리
        });
    },
    async checkVoteStatus(chatId) {
      const userId = this.$store.getters.user.id; // 사용자 ID 가져오기
      const database = this.$store.state.firebaseDatabase;

      // 찬성과 반대 모두 검사
      const proVoteRef = ref(database, `chats/${chatId}/pro/${userId}`);
      const conVoteRef = ref(database, `chats/${chatId}/con/${userId}`);

      // 찬성 투표 확인
      onValue(proVoteRef, (snapshot) => {
        if (snapshot.exists()) {
          this.setHasVoted(true); // 찬성 투표가 있으면 hasVoted를 true로 설정
          this.myVote = "pro"
        }
      });

      // 반대 투표 확인
      onValue(conVoteRef, (snapshot) => {
        if (snapshot.exists()) {
          this.setHasVoted(true); // 반대 투표가 있으면 hasVoted를 true로 설정
          this.myVote = "con"
        }
      });
    },
    getImage(voteType) {
      // console.log('Image URL:', this.voteImages[voteType]);
      return this.voteImages[voteType] || 'https://randomuser.me/api/portraits/men/5.jpg';
    }
  }
}
</script>

<style scoped>
.button-container {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 200px;
  overflow: visible;
}

.glow-effect {
  position: absolute;
  top: -20%;
  left: -10%;
  right: -10%;
  bottom: -30%;
  border-radius: 50%;
  z-index: 1;
}

.transparent-btn {
  position: relative;
  background-color: transparent !important;
  box-shadow: none !important;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  margin-top: -20px; /* 버튼을 위로 이동 */
}

.transparent-btn ::v-deep .v-btn__content {
  width: 100%;
  height: 100%;
}

.transparent-btn ::v-deep .v-image {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.hover-effect {
  overflow: hidden;
}

.hover-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.6s;
  z-index: 3;
}

.hover-effect:hover::before {
  left: 100%;
}

.hover-effect:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.pro-glow {
  background: radial-gradient(circle, rgba(0,255,0,0.5) 0%, rgba(0,255,0,0) 70%);
  /* animation: pulse-green 2s infinite; */
}

.con-glow {
  background: radial-gradient(circle, rgba(255,165,0,0.5) 0%, rgba(0,255,0,0) 70%);
  /* animation: pulse-orange 2s infinite; */
}

@keyframes pulse-green {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

.description {
  font-size: 1.0rem;
  font-weight: bold;
  color: #718096;
  text-align: left;
  z-index: 5;
}

.positive {
  color: #00C853;

}

.negative {
  color: #E64A19; /* deep-orange-darken-2 */

}

.vote-image {
  max-width: 100px;
  max-height: 170px;
  width: 100%;
  height: auto;
  object-fit: contain;
}
</style>
