<template>
  <v-container
    ref="chatlistContainer"
    fluid
    class="d-flex flex-column pa-0"
    :style="containerStyle"
  >
    <v-card
      class="mb-2 search-card"
      elevation="0"
    >
      <v-card-text>
        <v-text-field
          v-model="searchQuery"
          label="주제 검색"
          prepend-inner-icon="mdi-magnify"
          rounded
          filled
          dense
          hide-details
          :color="$vuetify.theme.dark ? 'white' : 'primary'"
          @input="searchChats"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-progress-circular
                v-if="loading"
                size="24"
                color="primary"
                indeterminate
              />
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-card-text>
    </v-card>
    <div
      ref="chatlistScrollContainer"
      class="flex-grow-1 overflow-y-auto pa-4"
      @scroll="onScroll"
    >
      <v-row>
        <v-col
          v-for="chat in paginatedChats"
          :key="chat.key"
          cols="12"
        >
          <v-card
            class="mx-auto chat-card"
            elevation="2"
            :class="[
              { 'on-hover': hoverChat === chat.key },
              { 'pro-voted': userVotes[chat.key] === 'pro' },
              { 'con-voted': userVotes[chat.key] === 'con' }
            ]"
            style="cursor: pointer; width: 100%;"
            @mouseover="hoverChat = chat.key"
            @mouseleave="hoverChat = null"
            @click="enterChat(chat)"
          >
            <v-card-text>
              <div class="d-flex flex-column flex-sm-row justify-start align-center">
                <span class="chat-title mb-2 mb-sm-0">"{{ chat.name }}"</span>
                <div class="d-flex justify-space-between align-center w-100">
                  <div class="d-flex justify-start align-center icon-group">
                    <v-badge
                      :content="chat.approvals.toString()"
                      color="#00C853"
                      offset-x="3"
                      offset-y="10"
                      class="mr-2"
                    >
                      <v-icon color="green">
                        mdi-thumb-up-outline
                      </v-icon>
                    </v-badge>
                    <v-badge
                      :content="chat.disapprovals.toString()"
                      color="#E64A19"
                      offset-x="0"
                      offset-y="10"
                      class="ml-3"
                    >
                      <v-icon color="orange">
                        mdi-thumb-down-outline
                      </v-icon>
                    </v-badge>
                  </div>

                  <v-btn
                    :color="chat.hasVoted ? 'grey' : 'primary'"
                    text
                    class="mt-2 mt-sm-0 vote-btn"
                    @click.stop="enterChat(chat)"
                  >
                    {{ chat.hasVoted ? 'Voted' : 'Unvoted' }}
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="pagination-container py-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="$vuetify.breakpoint.smAndDown ? 7 : 8"
        @input="loadChatsByPage"
      />
    </div>
  </v-container>
</template>

<script>
import { ref, query, orderByKey, limitToLast, get, update, startAt, orderByChild, equalTo, onValue } from 'firebase/database'
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      allChats: [], // 모든 채팅을 저장하는 배열 추가
      loading: false,
      hoverChat: null,
      page: 1,
      pageSize: 5,
      totalChats: 0,
      searchQuery: '',
      paginatedChats: [],
      lastLoadedKey: null,
      userVotes: {},
    }
  },
  computed: {
    currID() {
      return this.$store.getters.currID
    },
    user() {
      return this.$store.getters.user
    },
    totalPages() {
      return Math.ceil(this.totalChats / this.pageSize);
    },
    containerStyle() {
      return {
        height: this.$vuetify.breakpoint.smAndDown ? '90vh' : '88vh',
        display: 'flex',
        flexDirection: 'column',
      }
    },
  },
  watch: {
    userVotes: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted() {
    this.userVotes = {};
    this.initializeChats();
  },
  methods: {
    ...mapActions(['setCurrID', 'setIsViewList']),

    async initializeChats() {
      this.loading = true;
      const database = this.$store.state.firebaseDatabase;
      const chatsRef = ref(database, 'chats');

      try {
        const snapshot = await get(query(chatsRef, orderByKey()));
        this.totalChats = snapshot.size;

        // 모든 채팅 데이터를 가져와 처리
        const chatPromises = [];
        snapshot.forEach((childSnapshot) => {
          let chat = childSnapshot.val();
          chat.key = childSnapshot.key;
          chatPromises.push(this.processChat(chat));
        });

        this.allChats = await Promise.all(chatPromises);
        this.allChats.reverse(); // 최신 순으로 정렬

        this.loadChatsByPage(1);
      } catch (error) {
        console.error("Error initializing chats:", error);
      } finally {
        this.loading = false;
      }
    },

    loadChatsByPage(page) {
      const startIndex = (page - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.paginatedChats = this.allChats.slice(startIndex, endIndex);
      this.page = page;
    },

    async searchChats() {
      if (!this.searchQuery.trim()) {
        return this.loadChatsByPage(1);
      }

      this.loading = true;
      const query = this.searchQuery.toLowerCase();

      try {
        // 클라이언트 사이드에서 검색 수행
        const filteredChats = this.allChats.filter(chat =>
          chat.name.toLowerCase().includes(query)
        );

        this.paginatedChats = filteredChats.slice(0, this.pageSize);
        this.totalChats = filteredChats.length;
        this.page = 1;
      } catch (error) {
        console.error("Error searching chats:", error);
      } finally {
        this.loading = false;
      }
    },

    async processChat(chat) {
      await Promise.all([
        this.getUserCountForChat(chat),
        this.getVotingResultForChat(chat),
        this.checkVoteStatus(chat)
      ]);
      return chat;
    },

    async checkVoteStatus(chat) {
      const chatId = chat.key;
      const userId = this.user.id;
      const database = this.$store.state.firebaseDatabase;

      const proVoteRef = ref(database, `chats/${chatId}/pro/${userId}`);
      const conVoteRef = ref(database, `chats/${chatId}/con/${userId}`);

      chat.hasVoted = false;

      const checkVote = (snapshot, voteType) => {
        if (snapshot.exists()) {
          chat.hasVoted = true;
          this.userVotes = Object.assign({}, this.userVotes, { [chatId]: voteType });
        }
      };

      onValue(proVoteRef, (snapshot) => checkVote(snapshot, 'pro'));
      onValue(conVoteRef, (snapshot) => checkVote(snapshot, 'con'));
    },

    enterChat(chat) {
      let chatId = chat.key;
      let time = new Date().valueOf();

      if (chat.isAlreadyJoined) {
        this.setCurrID(chatId);
        this.setIsViewList(false);
      } else {
        let updates = {};
        updates['/chat_members/' + chatId + '/users/' + this.user.id] = { timestamp: time };
        updates['users/' + this.user.id + '/chats/' + chatId] = { timestamp: time };

        const database = this.$store.state.firebaseDatabase;
        update(ref(database), updates).then(() => {
          this.setCurrID(chatId);
          this.setIsViewList(false);
        });
      }
    },

    onScroll() {
      const container = this.$refs.chatlistContainer;
      if (!container) return;
    },

    async getUserCountForChat(chat) {
      const database = this.$store.state.firebaseDatabase;
      const chatMembersRef = ref(database, 'chat_members/' + chat.key + '/users');

      try {
        const snapshot = await get(chatMembersRef);
        chat.userCount = snapshot.size;
        chat.isAlreadyJoined = snapshot.hasChild(this.user.id);
      } catch (error) {
        console.error("Error getting user count for chat:", error);
      }
    },

    async getVotingResultForChat(chat) {
      const database = this.$store.state.firebaseDatabase;
      const chatRef = ref(database, 'chats/' + chat.key);

      try {
        const snapshot = await get(chatRef);
        if (snapshot.exists()) {
          const { pro, con } = snapshot.val();
          chat.approvals = this.getMessageCount(pro);
          chat.disapprovals = this.getMessageCount(con);
        } else {
          chat.approvals = 0;
          chat.disapprovals = 0;
        }
      } catch (error) {
        console.error("Error getting voting result for chat:", error);
      }
    },

    getMessageCount(messages) {
      if (!messages) return 0;
      return Array.isArray(messages) ? messages.length : Object.keys(messages).length;
    },
  }
}
</script>

<style scoped>
.chat-card {
  transition: all 0.3s ease-in-out;
}

.chat-card.on-hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.12);
}

.chat-title {
  white-space: normal;
  overflow-wrap: break-word;
  width: 75%;
  font-size: 1rem;
  font-weight: bold;
  color: #718096;
  text-align: left;
}

.pagination-container {
  position: sticky;

  background-color: inherit;
  z-index: 1;
}

.search-card {
  background-color: transparent;
}

.search-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.theme--dark .search-card {
  background-color: rgba(0, 0, 0, 0.2);
}

.theme--dark .v-text-field--filled > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.pro-voted {
  background: linear-gradient(to left, #c8e6c9, #ffffff);
}

.con-voted {
  background: linear-gradient(to left, #ffccbc, #ffffff);
}

.icon-group {
  width: 12%; /* 아이콘 그룹의 너비 설정 */
}

.icon-group .v-badge {
  margin-right: 8px; /* 아이콘과 아이콘 사이 간격 */
}

.vote-btn {
  min-width: 75px; /* 버튼의 최소 너비 */
  width: 13%; /* 버튼의 너비 설정 */
  text-align: right;
}

@media (max-width: 600px) {
  .chat-title {
    font-size: 0.9rem;
    width: auto;
    flex-grow: 1;
    padding: 0 4px; /* Small padding on the sides */
  }

  .v-card-text {
    padding: 8px 16px; /* Reduce padding inside cards */
  }

  .vote-btn {
    font-size: 0.8rem;
    min-width: 50px; /* 작은 화면에서 버튼 너비 조정 */
    margin-left: 20%; /* 작은 화면에서 아이콘 그룹과 버튼 사이 간격 */
  }

  .icon-group .v-badge {
    margin-right: 4px; /* 작은 화면에서 아이콘과 아이콘 사이 간격 */
  }

  .d-flex.align-center {
    flex-wrap: nowrap; /* 아이콘과 버튼이 같은 줄에 위치하도록 함 */
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .chat-title {
    font-size: 0.95rem;
  }
}

@media (min-width: 961px) {
  .chat-title {
    font-size: 1rem;
  }
}

/* 새로운 스타일 추가 */
@media (max-width: 960px) {
  .flex-grow-1.overflow-y-auto {
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
