<template>
  <v-container fluid class="pa-0">
    <v-row justify="center">
      <v-col cols="10" md="8" lg="6">
        <v-card flat class="transparent">
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10" md="8" lg="6">
        <DisqusBoard :pageUrl="currentPageUrl" :pageIdentifier="pageId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DisqusBoard from '@/components/DisqusBoard.vue'

export default {
  components: {
    DisqusBoard
  },
  data() {
    return {
      currentPageUrl: 'https://debate.hhuu.io/board',
      pageId: ''
    }
  }
}
</script>