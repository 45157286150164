<template>
  <v-row
    v-if="suggestions.length > 0"
    justify="center"
    class="mt-2"
  >
    <v-col
      cols="2"
      class="d-flex justify-center align-center"
    >
      <div style="font-size: 14px; font-weight: bold;">
        AI 추천
      </div>
    </v-col>
    <v-col cols="8">
      <div class="suggestions-wrapper">
        <button
          v-for="(suggestion, index) in suggestions"
          :key="index"
          class="suggestion-btn"
          @click="$emit('select-suggestion', suggestion)"
        >
          "{{ suggestion }}"
        </button>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SuggestionsRow',
  props: {
    suggestions: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.suggestions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.suggestion-btn {
  width: 100%;
  text-align: left;
  white-space: normal;
  height: auto;
  padding: 5px 5px;
  background-color: transparent;
  color: #333;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion-btn:hover {
  background-color: #e0e0e0;
}
</style>
