<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!-- 클래스 추가 및 padding 제거 -->
    <v-row no-gutters>
      <v-col
        cols="12"
        class="text-center"
      >
        <div
          ref="chatContainer"
          class="chat-container"
          @scroll="onScroll"
        >
          <message
            :messages="messages"
            @imageLoad="scrollToEnd"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Message from './parts/Message.vue'
import { ref, onChildAdded, query, orderByKey, endAt, limitToLast, get } from 'firebase/database'

export default {
  components: {
    message: Message,
  },
  data() {
    return {
      chatMessages: [],
      currentRef: null,
      loading: false,
      totalChatHeight: 0,
    }
  },
  computed: {
    currID() {
      return this.$store.getters.currID;
    },
    messages() {
      return this.chatMessages
    },
    username() {
      return "주제"
    },
    onNewMessageAdded() {
      const that = this;
      return function (snapshot, newMessage = true) {
        let message = snapshot.val(); // 스냅샷에서 메시지 데이터 가져오기
        message.key = snapshot.key; // 메시지 키 저장
        /*eslint-disable */
        var urlPattern =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi; // URL 패턴
        /*eslint-enable */
        // 메시지 내용 HTML 인코딩
        message.content = message.content
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;');
        // URL을 하이퍼링크로 변환
        message.content = message.content.replace(
          urlPattern,
          "<a href='$1'>$1</a>"
        );
        // 새 메시지인지 확인 후 처리
        if (!newMessage) {
          that.chatMessages.unshift(that.processMessage(message)); // 이전 메시지로 추가
          that.scrollTo(); // 스크롤 조정
        } else {
          that.chatMessages.push(that.processMessage(message)); // 새 메시지로 추가
          // that.scrollToEnd(); // 스크롤을 끝으로 이동
        }
      };
    },
  },
  watch: {
    currID(newID) {
      if (newID) {
        this.loadChat();
      }
    }
  },
  mounted() {
    this.loadChat();
  },
  methods: {
    loadChat() {
      if (!this.currID) {
        // console.error("currID is null or undefined");
        return;
      }
      this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
      this.loading = false;
      if (this.currID !== undefined) {
        this.chatMessages = [];
        let chatID = this.currID;
        const database = this.$store.state.firebaseDatabase;
        this.currentRef = ref(database, 'messages/' + chatID + '/messages');
        onChildAdded(this.currentRef, this.onNewMessageAdded);
      }
    },
    onScroll() {
      let scrollValue = this.$refs.chatContainer.scrollTop;
      const that = this;
      if (scrollValue < 100 && !this.loading) {
        this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
        this.loading = true;
        let chatID = this.currID;
        let currentTopMessage = this.chatMessages[0];
        if (currentTopMessage === undefined) {
          this.loading = false;
          return;
        }
        const database = this.$store.state.firebaseDatabase;
        const messagesRef = ref(database, 'messages/' + chatID + '/messages');
        const messagesQuery = query(messagesRef, orderByKey(), endAt(currentTopMessage.key), limitToLast(20));
        get(messagesQuery).then((snapshot) => {
          let tempArray = [];
          snapshot.forEach((item) => {
            tempArray.push(item);
          });
          // tempArray의 길이를 확인하여 안전하게 접근
          if (tempArray.length < 2 || tempArray[0].key === tempArray[1].key) return;
          tempArray.reverse();
          tempArray.forEach((child) => {
            that.onNewMessageAdded(child, false);
          });
          that.loading = false;
        });
      }
    },
    processMessage(message) {
      /*eslint-disable */
      var imageRegex = /([^\s\']+).(?:jpg|jpeg|gif|png)/i; // 이미지 URL 패턴
      /*eslint-enable */
      if (imageRegex.test(message.content)) {
        message.image = imageRegex.exec(message.content)[0]; // 이미지 URL 저장
      }
      var emojiRegex =
        /([\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{2934}-\u{1f18e}])/gu; // 이모지 패턴
      if (emojiRegex.test(message.content)) {
        message.content = message.content.replace(
          emojiRegex,
          '<span class="emoji">$1</span>' // 이모지를 HTML로 변환
        );
      }
      return message; // 처리된 메시지 반환
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector('.chat-container'); // 채팅 컨테이너 선택
        container.scrollTop = container.scrollHeight; // 스크롤을 끝으로 이동
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight; // 현재 높이 가져오기
        let difference = currentHeight - this.totalChatHeight; // 높이 차이 계산
        var container = this.$el.querySelector('.chat-container'); // 채팅 컨테이너 선택
        container.scrollTop = difference; // 스크롤 조정
      });
    },
  }
}
</script>

<style>
.scrollable {
  overflow-y: auto; /* 세로 스크롤 가능 */
  height: 100vh; /* 높이 100vh */
}
.typer {
  box-sizing: border-box; /* 박스 모델 설정 */
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  bottom: 0; /* 하단에 위치 */
  height: 4.9rem; /* 높이 설정 */
  width: 100%; /* 너비 100% */
  background-color: #fff; /* 배경색 흰색 */
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
}
.typer input[type='text'] {
  position: absolute; /* 절대 위치 */
  left: 2.5rem; /* 왼쪽 여백 */
  padding: 1rem; /* 패딩 설정 */
  width: 80%; /* 너비 80% */
  background-color: transparent; /* 배경 투명 */
  border: none; /* 테두리 없음 */
  outline: none; /* 아웃라인 없음 */
  font-size: 1.25rem; /* 글자 크기 */
}
.chat-container {

  box-sizing: border-box; /* 박스 모델 설정 */
  height: calc(100vh - 13.5rem); /* 전체 높이에서 여백 제외 */
  overflow-y: auto; /* 세로 스크롤 가능 */
  padding: 10px; /* 패딩 설정 */
  background-color: #f2f2f2; /* 배경색 회색 */
}

.message {
  margin-bottom: 3px; /* 메시지 간격 */
}
.message.own {
  text-align: right; /* 자신의 메시지 오른쪽 정렬 */
}
.message.own .content {
  background-color: lightskyblue; /* 자신의 메시지 배경색 */
}
.chat-container .username {
  font-size: 18px; /* 사용자 이름 글자 크기 */
  font-weight: bold; /* 사용자 이름 굵게 */
}
.chat-container .content {
  padding: 8px; /* 메시지 패딩 */
  background-color: lightgreen; /* 메시지 배경색 */
  border-radius: 10px; /* 모서리 둥글게 */
  display: inline-block; /* 인라인 블록 */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12); /* 그림자 효과 */
  max-width: 50%; /* 최대 너비 50% */
  word-wrap: break-word; /* 단어 줄바꿈 */
}
@media (max-width: 480px) {
  .chat-container .content {
    max-width: 60%; /* 모바일에서 최대 너비 60% */
  }
}
</style>
