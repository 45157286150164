import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import router from './router'
import store from './store'
import './assets/css/fonts.css';
import '@mdi/font/css/materialdesignicons.min.css';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// 이미지 import
import proImage from '@/assets/images/pro-g-s.png';
import conImage from '@/assets/images/con-g-s.png';
import proImage_s from '@/assets/images/pro-g-ss.png';
import conImage_s from '@/assets/images/con-g-ss.png';
import logoImg_w from '@/assets/images/logo-ai-debate-W-ss.png';
import logoImg_b from '@/assets/images/logo-ai-debate-B-ss.png';
import favicon from '@/assets/images/favicon.png';

// 이미지 프리로딩 및 전역 객체 생성
const preloadedImages = {
  pro: proImage,
  con: conImage,
  pro_s: proImage_s,
  con_s: conImage_s,
  logo_w: logoImg_w,
  logo_b: logoImg_b,
  favicon: favicon
};

// 이미지 프리로딩
Object.entries(preloadedImages).forEach(([key, src]) => {
  const img = new Image();
  img.src = src;
  img.onload = () => {
    // console.log(`Image ${key} loaded successfully`);
  };
  img.onerror = () => {
    console.error(`Failed to load image ${key}`);
  };
});

// Vue 인스턴스에 전역으로 추가
Vue.prototype.$preloadedImages = preloadedImages;

// 파비콘 동적 설정
const link = document.querySelector("link[rel~='icon']");
if (link) {
  link.href = favicon;
} else {
  const newLink = document.createElement('link');
  newLink.rel = 'icon';
  newLink.href = favicon;
  document.head.appendChild(newLink);
}


Vue.config.productionTip = false

const firebaseConfig = {
  // Firebase 구성 정보를 여기에 입력하세요
  apiKey: "AIzaSyAR8JRwhxm7GBB0HynyDzrJdbFN4F3WLRY",
  authDomain: "hhuu-ai-chat.firebaseapp.com",
  databaseURL: "https://hhuu-ai-chat-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hhuu-ai-chat",
  storageBucket: "hhuu-ai-chat.appspot.com",
  messagingSenderId: "1065146623127",
  appId: "1:1065146623127:web:ed313f7655613b244c35d2",
  measurementId: "G-PP6YN1VPPP"
};

// Firebase 초기화
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

// Vue 인스턴스를 생성하기 전에 store에 Firebase 객체를 설정합니다.
store.commit('setFirebaseApp', firebaseApp);
store.commit('setFirebaseAuth', auth);
store.commit('setFirebaseDatabase', database);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LfJqRIqAAAAADkl5IMmQNj3BEaG2yltCzJr4w9O'),
  isTokenAutoRefreshEnabled: true
});

// Vue 인스턴스에 AppCheck 추가
Vue.prototype.$appCheck = appCheck;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
