var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-list pa-3"},[_vm._l((_vm.messages),function(message,index){return [(message.user !== '주제')?_c('v-card',{key:message.id || `message-${index}`,class:[
        'chat-item',
        message.user === '찬성AI' ? 'other-message' :
        message.user === '반대AI' ? 'user-message' :
        'center-message',
        'ma-3'
      ],attrs:{"elevation":"2","color":_vm.getCardColor(message.user)}},[_c('v-list-item',[_c('v-list-item-content',[(message.user === '찬성AI')?_c('v-list-item-title',{staticClass:"chat-username d-flex align-center",staticStyle:{"color":"#388E3C"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32","rounded":"0"}},[_c('v-img',{attrs:{"src":_vm.getAvatarImage(message.user)}})],1),_vm._v(" "+_vm._s(message.user)+" ")],1):(message.user === '반대AI')?_c('v-list-item-title',{staticClass:"chat-username d-flex align-center justify-end",staticStyle:{"color":"#FF5722"}},[_vm._v(" "+_vm._s(message.user)+" "),_c('v-avatar',{staticClass:"ml-2",attrs:{"size":"32","rounded":"0"}},[_c('v-img',{attrs:{"src":_vm.getAvatarImage(message.user)}})],1)],1):_vm._e(),_c('v-list-item-subtitle',{staticClass:"chat-content"},[_c('div',{staticClass:"message-text",domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(message.content))}})])],1)],1)],1):_vm._e(),(_vm.shouldShowCircular(message, index))?_c('v-progress-circular',{key:`circular-${index}`,class:_vm.getCircularClass(message.user),attrs:{"indeterminate":"","color":_vm.getCircularColor(message.user)}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }