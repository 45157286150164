<template>
  <div class="kakao-ad-wrapper">
    <ins
      :id="adId"
      class="kakao_ad_area"
      :style="{ display: isLoaded ? 'block' : 'none' }"
      :data-ad-unit="adUnit"
      :data-ad-width="adWidth"
      :data-ad-height="adHeight"
    />
  </div>
</template>

<script>
export default {
  name: 'KakaoAd',
  props: {
    adUnit: {
      type: String,
      required: true
    },
    adWidth: {
      type: String,
      required: true
    },
    adHeight: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoaded: false,
      adId: `kakao_ad_${this._uid}`,
      retryCount: 0,
      maxRetries: 3
    }
  },
  mounted() {
    this.loadKakaoAdsScript()
  },
  methods: {
    loadKakaoAdsScript() {
      if (window.kakao && window.kakao.ads) {
        this.loadAd()
      } else {
        const script = document.createElement('script')
        script.onload = () => {
          this.loadAd()
        }
        script.onerror = (error) => {
          console.error('Failed to load Kakao ads script:', error)
          this.retryLoadScript()
        }
        script.async = true
        script.src = '//t1.daumcdn.net/kas/static/ba.min.js'
        document.head.appendChild(script)
      }
    },
    loadAd() {
      if (window.kakao && window.kakao.ads) {
        window.kakao.ads.load();
        this.$nextTick(() => {
          if (window.kakao && window.kakao.ads && window.kakao.ads.display) {
            try {
              window.kakao.ads.display(this.adId, this.adUnit, this.adWidth, this.adHeight);
              this.isLoaded = true;
            } catch (error) {
              console.error(`Failed to display ad unit ${this.adUnit}:`, error);
            }
          } else {
            console.error('Kakao ads display function is not available');
          }
        });
      } else {
        console.error('Kakao ads object is not available');
        this.retryLoadAd();
      }
    },
    reloadAd() {
      this.isLoaded = false
      this.$nextTick(() => {
        const adElement = document.getElementById(this.adId)
        if (adElement) {
          adElement.innerHTML = ''
        }
        this.loadAd()
      })
    },
    retryLoadScript() {
      if (this.retryCount < this.maxRetries) {
        this.retryCount++
        setTimeout(() => {
          this.loadKakaoAdsScript()
        }, 1000 * this.retryCount)
      }
    },
    retryLoadAd() {
      if (this.retryCount < this.maxRetries) {
        this.retryCount++
        setTimeout(() => {
          this.loadAd()
        }, 1000 * this.retryCount)
      }
    }
  }
}
</script>
