import Vue from 'vue'
import Vuex from 'vuex'

import ChatModule from './ChatModule'
import AuthModule from './AuthModule'
import { ref, get, onValue } from 'firebase/database';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    chat: ChatModule
  },
  state: {
    currID : null,
    hasVoted : false,
    voteResult: null,
    isViewList : false,
    loading: false,
    error: null,
    onlineUsers: [],
    firebaseApp: null,
    firebaseAuth: null,
    firebaseDatabase: null
  },
  mutations: {
    SET_CURR_ID(state, id) {
      state.currID = id; // currID 업데이트
    },
    SET_HAS_VOTED(state, payload){
      state.hasVoted = payload
    },
    SET_VOTE_RESULT(state, result) {
      state.voteResult = result;
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
    setOnlineUsers (state, payload) {
      state.onlineUsers = payload
    },
    setFirebaseApp(state, app) {
      state.firebaseApp = app;
    },
    setFirebaseAuth(state, auth) {
      state.firebaseAuth = auth;
    },
    setFirebaseDatabase(state, database) {
      state.firebaseDatabase = database;
    },
    setResetCurrID(state) { // resetCurrID mutation 추가
      state.currID = null; // currID 초기화
      state.hasVoted = false; // 투표여부 초기화
    },
    SET_IS_VIEW_LIST(state, payload){
      state.isViewList = payload;
    }
  },
  actions: {
    loadOnlineUsers ({commit, state}) {
      const db = state.firebaseDatabase;
      if (!db) {
        console.error('Firebase Database is not initialized');
        return;
      }
      onValue(ref(db, 'presence'), (snapshot) => {
        let result = []
        result[0] = snapshot.size
        result[1] = snapshot.val()
        commit('setOnlineUsers', result)
      })
    },
    clearError ({commit}) {
      commit('clearError')
    },
    setCurrID({ commit }, id) {
      commit('SET_CURR_ID', id);
    },
    resetCurrID({ commit }) {
      commit('setResetCurrID');
    },
    setHasVoted({ commit }, payload) {
      commit('SET_HAS_VOTED', payload);
    },
    setIsViewList({commit}, payload){
      commit('SET_IS_VIEW_LIST', payload);
    },
    async updateVoteResult({ commit, state }, { chatId, voteType }) {
      const database = state.firebaseDatabase;
      const chatRef = ref(database, 'chats/' + chatId);
      const snapshot = await get(chatRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const result = {
          pro: Object.keys(data.pro || {}).length,
          con: Object.keys(data.con || {}).length,
        };
        commit('SET_VOTE_RESULT', result);
      }
    },
  },
  getters: {
    currID: (state) => state.currID, // currID getter
    isViewList: (state) => state.isViewList,
    hasVoted: (state) => state.hasVoted,
    voteResult: (state) => state.voteResult,
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
    onlineUsers (state) {
      return state.onlineUsers
    }
  }
})
