<template>
  <v-container
    fluid
    class="create-container"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        md="10"
        lg="10"
      >
        <form @submit.prevent="openDiscussion">
          <div
            class="input-wrapper"
            :class="{ 'is-focused': isFocused }"
          >
            <input
              ref="chatNameInput"
              v-model="chatName"
              placeholder="주제를 입력 하세요"
              class="custom-input"
              maxlength="50"
              @focus="isFocused = true"
              @blur="isFocused = false"
            >
            <button
              type="submit"
              class="create-btn"
              :disabled="!chatName"
            >
              토론시작
            </button>
          </div>
        </form>
      </v-col>
    </v-row>

    <suggestions-row
      :suggestions="suggestions"
      @select-suggestion="createChatWithSuggestion"
    />

    <discussion-modal
      v-model="showModal"
      :title="modalTitle"
      :chat-name="chatName"
      :loading="loading"
      :server-response="serverResponse"
      :is-discussion-mode="isDiscussionMode"
      @confirm="handleConfirm"
      @cancel="cancelCreateChat"
    />
  </v-container>
</template>

<script>
import { ref, push, update } from 'firebase/database'
import axios from 'axios'
import SuggestionsRow from './parts/SuggestionsRow.vue'
import DiscussionModal from './parts/DiscussionModal.vue'
import { getToken } from "firebase/app-check";

export default {
  components: {
    SuggestionsRow,
    DiscussionModal
  },
  data() {
    return {
      chatName: '',
      loading: false,
      isFocused: false,
      suggestions: [],
      showModal: false,
      modalTitle: '',
      serverResponse: '',
      isDiscussionMode: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    username() {
      return "주제"
    },
  },
  mounted() {
    this.fetchSuggestions()
    this.$nextTick(() => {
      this.isFocused = true
      this.$refs.chatNameInput.focus()
    })
  },
  methods: {
    async fetchSuggestions() {
      if (this.suggestions.length >= 2) return;

      try {
        const appCheckTokenResponse = await getToken(this.$appCheck, /* forceRefresh */ false);
        const appCheckToken = appCheckTokenResponse.token;

        const response = await axios.post('https://generatesuggestionandexample-7yzf56bapq-an.a.run.app/generateSuggestionAndExample', {
          type: '샘플',
          data: '대중교통 요금을 인상하여 서비스 개선에 투자해야 한다, 재택근무를 계속해야 하며 직원의 생산성을 높인다, 블라인드 채용제를 시행해야 한다'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'X-Firebase-AppCheck': appCheckToken
          },
        });

        this.suggestions = this.processSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    },

    processSuggestions(data) {
      // console.log("1.최초 데이터 : ", data);
      if (typeof data !== 'object' || data === null) {
        console.log("2. null skip return []");
        return [];
      }

      if (Array.isArray(data)) {
        // 정상적인 케이스
        // ["대학교 입시에 수능 점수 비중을 줄여야 한다","학교 급식에 채식 메뉴를 의무적으로 제공해야 한다"]
        // 비정상 케이스 모두 처리
        // [
        //     {
        //         "찬성": "학교 수업에 스마트폰 사용을 허용해야 한다",
        //         "반대": "학교 수업에 스마트폰 사용을 금지해야 한다"
        //     },
        //     {
        //         "찬성": "모든 사람은 기본 소득을 받아야 한다",
        //         "반대": "모든 사람은 기본 소득을 받을 필요가 없다"
        //     }
        // ]
        // Case 3: Array of objects with '찬성' and '반대'
        // console.log("3.Array.isArray(data) : ", data.map(item => item.찬성 || item));
        return data.map(item => item.찬성 ? item.찬성 : item);
      }

      const values = Object.values(data);

      // case : {"토론 주제":["대중교통 이용 시 휴대폰 사용을 제한해야 한다","모든 사람은 최소한의 기본 소득을 보장받아야 한다"]}
      if (values.length === 1 && Array.isArray(values[0])) {
        // Case 1: {"토론 주제": ["...", "..."]}
        // console.log("4.values.length === 1 && Array.isArray(values[0]) : ", values[0]);
        return values[0];
      }

      // {"주제1":"사회적 거리두기는 개인의 자유를 제한한다","주제2":"온라인 교육은 전통 교육보다 효과적이다"}
      if (values.every(v => typeof v === 'string')) {
        // Case 2: {"주제1": "...", "주제2": "..."}
        // Case 4: {"토론 주제1": "...", "토론 주제2": "..."}
        // console.log("5.values.every(v => typeof v === 'string') : ", values);
        return values;
      }

      // {"data":{"샘플1":"인공지능 발전은 인류에게 도움이 된다","샘플2":"전자책은 종이책보다 장점이 많다"}}
      if(values.length < 2){
        // 응답이 하나의 배열로 2개가 전달되는 상황이 있어, 분리하여 저장
        let rs = [];
        rs = values.map(item => Object.values(item)[0]);
        rs.push(...values.map(item => Object.values(item)[1]));
        // console.log("6번 케이스 : ", rs);
        return rs;
      }
      // console.log("6. else : ", values.filter(v => typeof v === 'string'));
      // If none of the above, try to extract any string values
      // return values.filter(v => typeof v === 'string');
    },
    async openDiscussion() {
      if (this.chatName === '' || this.loading) return;

      this.showModal = true;
      this.modalTitle = "AI 문장 개선";
      this.isDiscussionMode = true;
      this.loading = true;
      this.serverResponse = '';

      try {
        const appCheckTokenResponse = await getToken(this.$appCheck, /* forceRefresh */ false);
        const appCheckToken = appCheckTokenResponse.token;

        const response = await axios.post('https://generatesuggestionandexample-7yzf56bapq-an.a.run.app/generateSuggestionAndExample', {
          type: '제안',
          data: this.chatName
        }, {
          headers: {
            'Content-Type': 'application/json',
            'X-Firebase-AppCheck': appCheckToken
          },
        });

        this.serverResponse = this.processServerResponse(response.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
        this.serverResponse = "서버 응답 오류";
      } finally {
        this.loading = false;
      }
    },
    processServerResponse(data) {
      const tmpArr = Object.values(data)
      return tmpArr.length >= 2 ? tmpArr[0] : tmpArr.join(', ')
    },
    createChatWithSuggestion(suggestion) {
      this.chatName = suggestion
      this.modalTitle = "AI추천 주제로 토론하기"
      this.isDiscussionMode = false
      this.showModal = true
      this.serverResponse = "선택하신 주제로 AI 토론을 시작하시겠습니까?"
    },
    async handleConfirm(type) {
      if (this.loading && type === 'improved') return

      this.loading = true

      try {
        if (type === 'improved' && this.isDiscussionMode) {
          this.chatName = Array.isArray(this.serverResponse)
            ? this.serverResponse.join(', ')
            : this.serverResponse
        }

        const newPostKey = await this.createNewChat()
        await this.sendMessage(newPostKey)
        this.showModal = false
        this.chatName = ''
      } catch (error) {
        console.error("Error creating chat:", error)
      } finally {
        this.loading = false
      }
    },
    async confirmCreateChat() {
      if (this.chatName === '' || this.loading) return

      this.loading = true

      try {
        const newPostKey = await this.createNewChat()
        await this.sendMessage(newPostKey)
        this.showModal = false
        this.chatName = ''
      } catch (error) {
        console.error("Error creating chat:", error)
      } finally {
        this.loading = false
      }
    },
    async createNewChat() {
      const time = new Date().valueOf()
      const database = this.$store.state.firebaseDatabase
      const chatsRef = ref(database, 'chats')
      const newPostRef = push(chatsRef)
      const newPostKey = newPostRef.key

      if (!newPostKey) {
        throw new Error("Failed to create a new chat post.")
      }

      const updates = {
        [`/chats/${newPostKey}`]: { name: this.chatName },
        [`/chat_members/${newPostKey}/users/${this.user.id}`]: { timestamp: time },
        [`users/${this.user.id}/chats/${newPostKey}`]: { timestamp: time }
      }

      await update(ref(database), updates)
      return newPostKey
    },
    async sendMessage(postKey) {
      if (this.chatName !== '') {
        await this.$store.dispatch('sendMessage', {
          username: this.username,
          content: this.chatName,
          date: new Date(new Date().getTime() + 9 * 60 * 60 * 1000).toString(),
          chatID: postKey
        })
        await this.$store.dispatch('setCurrID', postKey)
      }
    },
    cancelCreateChat() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.create-container {
  display: flex;
  flex-direction: column;
  min-height: 24vh;
  width: 100%;
  padding: 1rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #dfe1e5;
  box-shadow: 0 1px 3px rgba(32,33,36,0.08);
  transition: box-shadow 0.3s, border-color 0.3s;
  overflow: hidden;
}

.input-wrapper.is-focused {
  box-shadow: 0 1px 6px rgba(32,33,36,0.28);
  border-color: #4285f4;
}

.custom-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 12px 16px;
  width: 100%;
}

.create-btn {
  background-color: #4285f4;
  border: none;
  border-radius: 24px;
  color: #e0edfc;
  font-size: 14px;
  font-weight: bold;
  padding: 0 16px;
  margin-right: 3px;
  height: 44px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 20%;
}

.create-btn:hover {
  background-color: #e8e8e8;
  color: #4285f4;
  font-weight: bold;
  border: 2px solid #4285f4;
}

.create-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .create-container {
    padding: 0.5rem;
  }

  .custom-input {
    font-size: 14px;
    padding: 10px 12px;
  }

  .create-btn {
    font-size: 12px;
    padding: 0 12px;
    height: 40px;
  }
}
</style>
