<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        sm="10"
        md="10"
        lg="10"
        class="pr-2"
      >
        <div class="voting-result">
          <v-card
            flat
            class="transparent"
          >
            <v-card-title class="topic-title justify-center">
              "{{ topic }}"
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-column flex-sm-row justify-space-between align-center">
                <span
                  class="approval-text mb-2 mb-sm-0"
                  :style="{ width: $vuetify.breakpoint.smAndUp ? '10%' : '100%', textAlign: 'center' }"
                >찬성</span>
                <v-progress-linear
                  :value="100"
                  height="25"
                  color="grey lighten-2"
                  :style="{ width: $vuetify.breakpoint.smAndUp ? '80%' : '100%' }"
                >
                  <div class="progress-bar-wrapper">
                    <div
                      class="progress-bar-approval"
                      :style="{ width: approvalPercentage + '%' }"
                    >
                      <span>{{ agree }}</span>
                    </div>
                    <div
                      class="progress-bar-rejection"
                      :style="{ width: rejectionPercentage + '%' }"
                    >
                      <span>{{ disagree }}</span>
                    </div>
                  </div>
                </v-progress-linear>
                <span
                  class="rejection-text mt-2 mt-sm-0"
                  :style="{ width: $vuetify.breakpoint.smAndUp ? '10%' : '100%', textAlign: 'center' }"
                >반대</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        class="d-flex flex-column justify-space-between align-items-center mt-4 mt-sm-0"
      >
        <div class="button-container">
          <button
            class="action-btn create-btn mb-3 mb-sm-0"
            @click="createTopic"
          >
            새 토론 시작
          </button>
          <button
            v-if="hasVoted"
            class="action-btn reset-vote-btn mt-3 mt-sm-auto"
            @click="resetVote"
          >
            재 투표 하기
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { ref, onValue, get, remove } from 'firebase/database';

export default {
  data() {
    return {
      topic: "샘플 주제",
      agree: 0,
      disagree: 0
    }
  },
  computed:{
    ...mapGetters(['currID', 'chats', 'voteResult', 'hasVoted']),
    approvalPercentage() {
      const total = this.agree + this.disagree;
      return total ? (this.agree / total) * 100 : 50;
    },
    rejectionPercentage() {
      const total = this.agree + this.disagree;
      return total ? (this.disagree / total) * 100 : 50;
    }
  },
  watch: {
    voteResult: {
      handler(newValue) {
        if (newValue) {
          this.agree = newValue.pro;
          this.disagree = newValue.con;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchTopic(); // 컴포넌트가 마운트될 때 주제 가져오기
    this.listenToVoteChanges();
  },
  methods: {
    ...mapActions(['resetCurrID', 'setHasVoted']),
    createTopic() {
      this.resetCurrID();
    },
    async fetchTopic() {
      const database = this.$store.state.firebaseDatabase;
      const messagesRef = ref(database, 'chats/' + this.currID);
      const snapshot = await get(messagesRef);

      if (snapshot.exists()) {
        const { name, pro, con } = snapshot.val();

        this.topic = name || ''; // name이 없을 경우 빈 문자열 할당
        this.agree = this.getMessageCount(pro);
        this.disagree = this.getMessageCount(con);
      }
    },
    listenToVoteChanges() {
      const database = this.$store.state.firebaseDatabase;
      const chatRef = ref(database, 'chats/' + this.currID);

      onValue(chatRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.agree = this.getMessageCount(data.pro);
          this.disagree = this.getMessageCount(data.con);
        }
      });
    },
    // 메시지 개수를 계산하는 헬퍼 함수
    getMessageCount(messages) {
      if (!messages) return 0;
      return Array.isArray(messages) ? messages.length : Object.keys(messages).length;
    },
    async resetVote() {
      const database = this.$store.state.firebaseDatabase;
      const userId = this.$store.getters.user.id;
      const chatId = this.currID;

      // pro 노드 삭제 시도
      try {
        await remove(ref(database, `chats/${chatId}/pro/${userId}`));
      } catch (error) {
        console.log('Pro vote not found or already deleted');
      }

      // con 노드 삭제 시도
      try {
        await remove(ref(database, `chats/${chatId}/con/${userId}`));
      } catch (error) {
        console.log('Con vote not found or already deleted');
      }

      // Vuex store 업데이트
      this.setHasVoted(false);

      // 투표 결과 다시 가져오기
      this.fetchTopic();
    }
  },

}
</script>

<style scoped>
.voting-result {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.topic-title {
  /* 토론 주제 제목의 글자 크기 */
  font-size: 1.2rem;
  /* 토론 주제 제목의 글자 굵기 */
  font-weight: bold;
  /* 토론 주제 제목의 글자 색상 */
  color: #333;
  /* 토론 주제 제목에 그림자 효과 적용 */
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  /* 토론 주제 제목의 정렬 방식 */
  text-align: center;
  /* 텍스트가 너비를 벗어나면 숨김 처리 */
  overflow: hidden;
  /* 텍스트가 너비를 벗어나면 텍스트를 생략 기호로 표시 */
  text-overflow: ellipsis;
  /* 텍스트 줄 바꿈 방지 */
  white-space: nowrap;
}

.progress-bar-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.progress-bar-approval {
  background-color: #00C853;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding-left: 5px;
}

.progress-bar-rejection {
  background-color: #E64A19;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  padding-right: 5px;
}

.approval-text {
  color: #00C853;
  font-weight: bold;
}

.rejection-text {
  color: #E64A19;
  font-weight: bold;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.action-btn {
  width: 100%;
  max-width: 150px;
}

.create-btn,
.reset-vote-btn {
  border: none;
  border-radius: 24px;
  color: #e0edfc;
  font-size: 14px;
  font-weight: bold;
  padding: 0 16px;
  height: 44px;
  cursor: pointer;
}

.create-btn {
  background-color: #4285f4;
}

.create-btn:hover {
  background-color: #e8e8e8;
  color: #4285f4;
  border: 2px solid #4285f4;
}

.reset-vote-btn {
  background-color: #E64A19;
  margin-bottom: 10%;
}

.reset-vote-btn:hover {
  background-color: #e8e8e8;
  color: #E64A19;
  border: 2px solid #E64A19;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: row;
    justify-content: center;
    height: auto;
  }

  .action-btn {
    margin: 0 5px;
  }

  .reset-vote-btn {
    margin-top: 0 !important;
  }
}
</style>
