<template>
  <v-app>
    <v-app-bar
      app
      color="transparent"
      :elevation="0"
    >
      <!-- 로고 이미지 -->
      <v-img
        :src="getLogoImage()"
        max-height="40"
        max-width="150"
        contain
        class="mr-3"
        style="cursor: pointer;"
        @click="viewHome"
      />

      <!-- Google 번역 위젯 추가 -->
      <div class="google-translate-widget d-flex align-center ">
        <span class="translate-label">번역:</span>
        <div
          id="google_translate_element"
        />
      </div>

      <v-spacer />

      <v-toolbar-items
        v-if="!isBoard"
        v-for="item in menuItems"
        :key="item.route"
      >
        <v-btn
          class="list-btn"
          text
          small
          @click="navigateTo(item.route)"
        >
          <v-icon
            left
          >
            {{ item.icon }}
          </v-icon>
          <span class="hidden-xs-only">
            {{ item.title }}
          </span>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main class="main-content">
      <router-view />
    </v-main>

    <!-- Footer 추가 -->
    <v-footer
      app
      class="transparent-footer"
      fixed
      style="pointer-events: none;"
    >
      <v-container
        fluid
        class="pa-3"
      >
        <v-row no-gutters>
          <v-col class="text-right">
            <span
              class="text-gray"
              style="pointer-events: auto;"
            > © 2024 hhuu.io </span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data () {
    return {
      drawerToggle: false
    }
  },
  computed: {
    ...mapGetters(['isViewList']),
    menuItems () {
      let items = [
        {icon: "mdi-view-list", title: 'TOPIC LIST', route: '/discover'},
        {icon: "mdi-message-reply-text", title: 'BOARD', route: '/board'},
      ]
      return items
    },
    isBoard() {
      return this.$route.path === '/board'
    }
  },
  methods: {
    ...mapActions(['resetCurrID', 'setIsViewList']), // Vuex 액션 추가
    viewList(){
      this.setIsViewList(!this.isViewList);
    },
    viewHome(){
      this.setIsViewList(false);
      this.resetCurrID();
      this.$router.push('/');
    },
    getLogoImage(){
      // 테마에 따라 다른 이미지 파일을 로드
      if (this.$vuetify.theme.dark) {
        return this.$preloadedImages.logo_w;
      } else {
        return this.$preloadedImages.logo_b;
      }
    },
    navigateTo(route) {
      if (route === '/discover') {
        this.viewList();
      } else {
        this.$router.push(route);
      }
    }
  },
}
</script>

<style scoped>
.main-content {
  height: 100vh;
  padding-top: 0 !important; /* v-main의 기본 패딩 제거 */
  overflow-y: auto; /* 필요한 경우에만 스크롤 표시 */
}

.transparent-footer {
  background-color: transparent !important; /* 배경을 투명하게 설정 */
}

.text-gray {
  color: gray; /* 글자 색상을 회색으로 설정 */
}

.list-btn {
  border-radius: 20px;
  color: #4285f4;
  height: 36px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  padding: 0 16px;
  margin: 4px;
  transition: all 0.3s ease;
}

.list-btn:hover {
  background-color: rgba(66, 133, 244, 0.1);
}

.list-btn .v-icon {
  margin-right: 4px;
}

@media (max-width: 600px) {
  .main-content {
    height: calc(100vh - 56px); /* 모바일에서는 앱 바 높이가 56px */
  }
  .list-btn .v-icon {
    font-size: 2em;
  }
}

.google-translate-widget {
  position: fixed;
  top: 60px; /* 앱바 높이에 따라 조정 */
  left: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.translate-label {
  margin-right: 8px; /* 콤보박스와의 간격 조정 */
  font-size: 14px; /* 콤보박스와 같은 크기로 설정 */
  color: #333; /* 콤보박스와 같은 색상으로 설정 */
  font-weight: bold;
}

/* "Google 번역에서 제공" 문구 숨기기 */
::v-deep .skiptranslate.goog-te-gadget {
  font-size: 0;
}

::v-deep .VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display:none!important;
}

/* 구글 번역 콤보박스 스타일 변경 */
::v-deep .goog-te-combo {
  text-align: left;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  appearance: none; /* 기본 콤보박스 스타일 제거 */
  -webkit-appearance: none; /* Safari 및 Chrome에서 기본 스타일 제거 */
  -moz-appearance: none; /* Firefox에서 기본 스타일 제거 */
}

@media (max-width: 600px) {
  .google-translate-widget {
    position: static;
    margin-left: auto;
    margin-right: 16px;
  }

  .translate-label {
    font-size: 12px;
  }

  ::v-deep .goog-te-combo {
    font-size: 12px;
    padding: 4px 8px;
  }
}


</style>
