<template>
  <div>
    <div id="disqus_thread"></div>
  </div>
</template>

<script>
export default {
  name: 'DisqusBoard',
  props: {
    pageUrl: {
      type: String,
      required: true
    },
    pageIdentifier: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.loadDisqus();
  },
  methods: {
    loadDisqus() {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://ai-debate.disqus.com/embed.js';
      s.setAttribute('data-timestamp', +new Date());
      (d.head || d.body).appendChild(s);

      window.disqus_config = () => {
        this.page.url = this.pageUrl;
        this.page.identifier = this.pageIdentifier;
      };
    }
  }
}
</script>

<style scoped>
/* 필요한 경우 여기에 스타일을 추가하세요 */
</style>