import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Signin from '@/components/User/Signin'
import AuthGuard from './auth-guard'
import HomeEng from '@/components/HomeEng'
import Board from '@/components/Board' // 새로 추가된 Board 컴포넌트 import

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: AuthGuard
    },
    {
      path: '/login',
      name: 'Signin',
      component: Signin
    },
    {
      path: '/en',
      name: 'HomeEng',
      component: HomeEng,
      beforeEnter: AuthGuard
    },
    {
      path: '/board',
      name: 'Board',
      component: Board,
      beforeEnter: AuthGuard // 게시판도 인증이 필요하다고 가정
    },
  ],
  mode: 'history'
})