<template>
  <div class="chat-list pa-3">
    <template v-for="(message, index) in messages">
      <v-card
        v-if="message.user !== '주제'"
        :key="message.id || `message-${index}`"
        :class="[
          'chat-item',
          message.user === '찬성AI' ? 'other-message' :
          message.user === '반대AI' ? 'user-message' :
          'center-message',
          'ma-3'
        ]"
        elevation="2"
        :color="getCardColor(message.user)"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              v-if="message.user === '찬성AI'"
              class="chat-username d-flex align-center"
              style="color: #388E3C;"
            >
              <v-avatar
                size="32"
                class="mr-2"
                rounded="0"
              >
                <v-img
                  :src="getAvatarImage(message.user)"
                />
              </v-avatar>
              {{ message.user }}
            </v-list-item-title>
            <v-list-item-title
              v-else-if="message.user === '반대AI'"
              class="chat-username d-flex align-center justify-end"
              style="color: #FF5722;"
            >
              {{ message.user }}
              <v-avatar
                size="32"
                class="ml-2"
                rounded="0"
              >
                <v-img :src="getAvatarImage(message.user)" />
              </v-avatar>
            </v-list-item-title>
            <v-list-item-subtitle class="chat-content">
              <div
                class="message-text"
                v-html="renderMarkdown(message.content)"
              />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-progress-circular
        v-if="shouldShowCircular(message, index)"
        :key="`circular-${index}`"
        indeterminate
        :color="getCircularColor(message.user)"
        :class="getCircularClass(message.user)"
      />
    </template>
  </div>
</template>

<script>
import { marked } from 'marked';
import DOMPurify from 'dompurify';

export default {
  name: 'ChatMessage',
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      avatarImages: {
        '찬성AI': require('@/assets/images/pro-g-ss.png'),
        '반대AI': require('@/assets/images/con-g-ss.png'),
      }
    }
  },
  methods: {
    renderMarkdown(content) {
      const rawHTML = marked(content);
      return DOMPurify.sanitize(rawHTML);
    },
    getCardColor(user) {
      switch(user) {
      case '찬성AI':
        return 'light-green lighten-4';
      case '반대AI':
        return 'orange lighten-4';
      default:
        return 'grey lighten-4';
      }
    },
    getAvatarImage(user) {
      return this.avatarImages[user] || 'https://randomuser.me/api/portraits/men/5.jpg';
    },
    shouldShowCircular(message, index) {
      if (message.user === '요약') return false;
      if (index === this.messages.length - 1) {
        return ['주제', '찬성AI', '반대AI'].includes(message.user);
      }
      return false;
    },
    getCircularColor(user) {
      return user === '찬성AI' ? 'orange' : 'green';
    },
    getCircularClass(user) {
      return user === '찬성AI' ? 'right-circular' : 'left-circular';
    }
  },
}
</script>

<style scoped>
.chat-content {
  height: 100%;
  overflow-y: auto;
}
.chat-list {
  display: flex;
  flex-direction: column;
}
.chat-item {
  max-width: 80%;
  border-radius: 12px !important;
  transition: all 0.3s ease;
  text-align: left;
}
.chat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}
.user-message {
  align-self: flex-end;
}
.other-message {
  align-self: flex-start;
}
.center-message {
  align-self: center;
}
.chat-username {
  font-weight: bold;
  font-size: 1.2rem !important;
}
.chat-content {
  padding: 3px !important;
}
.message-text {
  white-space: pre-wrap;
  word-break: break-all;
  overflow-wrap: break-word;
}
.left-circular {
  align-self: flex-start;
  margin-left: 20px;
}
.right-circular {
  align-self: flex-end;
  margin-right: 20px;
}
</style>
