import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, onValue, get } from "firebase/database";

const AuthModule = {
  state: {
    user: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    }
  },
  actions: {
    async signUserUp({ commit }) {
      commit('setLoading', true)
      commit('clearError')
      const auth = getAuth()
      try {
        const tempAuthResult = await signInAnonymously(auth)
        const database = getDatabase()
        await set(ref(database, 'users/' + tempAuthResult.user.uid), {
          id: tempAuthResult.user.uid
        })
        commit('setLoading', false)
        commit('setUser', { id: tempAuthResult.user.uid })
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error)
      }
    },
    signUserIn({ commit, dispatch }) {
      commit('setLoading', true)
      commit('clearError')
      const auth = getAuth()

      return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            try {
              const database = getDatabase()
              const snapshot = await get(ref(database, 'users/' + user.uid))
              commit('setLoading', false)
              const newUser = {
                id: user.uid,
                username: snapshot.val() ? snapshot.val().username : null
              }
              commit('setUser', newUser)
              resolve()
            } catch (error) {
              console.log('sign Error :', error)
              commit('setLoading', false)
              commit('setError', error)
              reject(error)
            }
          } else {
            console.log("user 정보 없음, signUserUp 호출")
            dispatch('signUserUp')
              .then(resolve)
              .catch(reject)
          }
        })
      })
    }
  },
  getters: {
    user(state) {
      return state.user
    }
  }
}

export default AuthModule
