import { ref, push, set } from 'firebase/database'

const ChatModule = {
  state: {
    chats: {}
  },
  mutations: {
    setChats (state, payload) {
      payload["0"] = {name: "Default"}
      state.chats = payload
    }
  },
  actions: {
    loadUserChats(context) {
      let user = context.getters.user;
      const database = rootState.firebaseDatabase

      const userChatsRef = query(ref(database, `users/${user.id}/chats`), orderByChild("timestamp"));
      get(userChatsRef)
        .then(snapshot => {
          let chats = snapshot.val();
          if (chats == null) {
            chats = {};
          }

          const chatIds = Object.keys(chats);
          const chatPromises = chatIds.map(chatId => {
            chats[chatId].name = "Loading...";

            const chatRef = ref(database, `chats/${chatId}`);
            return get(chatRef)
              .then(chatSnapshot => {
                chats[chatId].name = chatSnapshot.val().name;
              });
          });

          Promise.all(chatPromises)
            .then(() => {
              context.commit('setChats', chats);
            })
            .catch(error => {
              console.error("Error loading chat names: ", error);
            });
        })
        .catch(error => {
          console.error("Error loading user chats: ", error);
        });
    },
    sendMessage ({ rootState }, payload) {
      let chatID = payload.chatID
      const message = {
        user: payload.username,
        content: payload.content,
        date: payload.date
      }

      const database = rootState.firebaseDatabase
      const messagesRef = ref(database, `messages/${chatID}/messages`)
      const newMessageRef = push(messagesRef)

      set(newMessageRef, message)
        .then(() => {
          // 메시지 전송 성공
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  getters: {
    chats (state) {
      return state.chats
    }
  }
}

export default ChatModule
